//
// Pricing card
//

.card-pricing {
	cursor: pointer;
	overflow: hidden;
	transform: scale(1);
	transition: transform 350ms ease;

	&::before {
		content: '';
		position: absolute;
		top: 40px;
		right: 40px;
		padding: 12px;
		text-align: center;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transform: scale(1);
		transition: transform 350ms ease;
	}
	&:hover {
		box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.25);
		// transform: scale(1.05);
		// transition: transform 350ms ease;

		.icon-shape {
			background: transparent !important;
			transition: background 350ms ease;

			i {
				transform: scale(1.5);
				transition: transform 350ms ease;
			}
		}

		&::before {
			transform: scale(10);
			transition: transform 350ms ease;
		}
	}

	&.bg-gradient-orange {
		background: #ffffff !important;
	}
	&.bg-gradient-green {
		background: #ffffff !important;
	}
	&.bg-gradient-purple {
		background: #ffffff !important;
	}
	&.bg-gradient-teal {
		background: #ffffff !important;
	}
	&.bg-gradient-red {
		background: #ffffff !important;
	}
	&.bg-gradient-yellow {
		background: #ffffff !important;
	}

	&.bg-gradient-orange::before {
		background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important;
	}
	&.bg-gradient-green::before {
		background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;
	}
	&.bg-gradient-purple::before {
		background: linear-gradient(87deg, #5f63c2 0, #845fc2 100%) !important;
	}
	&.bg-gradient-teal::before {
		background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
	}
	&.bg-gradient-red::before {
		background: linear-gradient(87deg, #f5365c 0, #f56036 100%) !important;
	}
	&.bg-gradient-yellow::before {
		background: linear-gradient(87deg, #ffd600 0, #beff00 100%) !important;
	}

	.icon-shape {
		transition: background 350ms ease;

		i {
			transform: scale(1);
			transition: transform 350ms ease;
		}
	}

	.card-header {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}
	.list-unstyled li {
		padding: 0.5rem 0;
		color: $gray-600;
	}
}

.card-pricing.popular {
	z-index: 1;
	border: 3px solid theme-color('primary') !important;
}

@include media-breakpoint-up(md) {
	.card-pricing.zoom-in {
		z-index: 1;
		transform: scale(1.1);
	}
}
