.bg-info {
	background-color: $primary !important;
}

.navbar-vertical.navbar-expand-xs {
	overflow: hidden;
}

// .react-bootstrap-table .order-4:after,
// .react-bootstrap-table .order-4:before,
// .react-bootstrap-table .caret-4-asc:after,
// .react-bootstrap-table .caret-4-asc:before,
// .react-bootstrap-table .caret-4-desc:after,
// .react-bootstrap-table .caret-4-desc:before {
// 	bottom: 15px !important;
// }

// Card overrides

.card-borderless {
	border-bottom: 0px;
}

.table-dropdown.dropdown-menu.show {
	display: flex;
}

.react-bootstrap-table th.sortable {
	position: relative;
	cursor: pointer;
}

.react-bootstrap-table > .button-table > tbody > tr {
	cursor: pointer;
}

// .react-bootstrap-table > .button-table > tbody > tr > td {
// 	padding-top: 0.2rem !important;
// 	padding-bottom: 0.2rem !important;
// 	padding-left: 0 !important;
// 	padding-right: 0.2rem !important;
// }

.react-bootstrap-table > .button-table > tbody > tr > td.button-cell {
	padding-top: 0.2rem !important;
	padding-bottom: 0.2rem !important;
	padding-left: 1.5rem !important;
	padding-right: 0.2rem !important;
}

.react-bootstrap-table-pagination {
	margin-right: auto;
	margin-left: auto;
	margin-top: 30px;
}

.react-bootstrap-table-pagination .dataTables_length,
.react-bootstrap-table-pagination .dataTables_info,
.react-bootstrap-table-pagination .dt-buttons {
	display: block;
	padding-left: calc(1.5rem - 15px);
}

.react-bootstrap-table-pagination-total {
	display: block;
	padding-left: calc(1.5rem - 15px);

	@include media-breakpoint-up(xl) {
		display: inline-block;
	}
}

.adapt {
	width: calc(100vw - 60px - 3rem);
	margin: 0;

	@include media-breakpoint-up(xl) {
		width: calc(100vw - 100px - 3rem);
	}
}

.g-sidenav-pinned .adapt {
	width: calc(100vw - 285px - 3rem);
}

.react-bootstrap-table {
	width: 100%;
	overflow-x: auto;
}

.fade.show .modal-dialog {
	animation-duration: 0.5s;
}

.buttons-copy {
	margin: 5px;
}
