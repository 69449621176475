//
// Card
//

.card {
	margin-bottom: $grid-gutter-width;

	@if $enable-shadows {
		box-shadow: $card-box-shadow;
		border: 0;
	}
}

.card .table tr {
	&:hover {
		background: #f8f9fe;
	}
}

.card .table tbody td {
	padding: 0;
	vertical-align: middle;

	a {
		display: block;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		color: #525f7f;
	}
}

.court-list-table .card .table tbody td {
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.court-list-table .dataTables_filter .form-control.court-filter {
	height: calc(0.45em + 1.25rem + 5px);
	padding: 0 0.5rem;
	display: inline-block;
	width: auto;
	margin: 0 0 0 10px;

	@include media-breakpoint-up(xl) {
		margin: 0 20px 0 10px;
	}
}

.court-list-table .card .table thead th input,
.court-list-table .card .table thead th select {
	height: calc(0.45em + 1.25rem + 5px);
	padding: 0 10px;
	display: none;
}

.court-list-table .card .table thead th label {
	// margin: 0 0 0 20px;
	display: none;
}

.case-list-table .dataTables_filter .form-control.case-filter {
	height: calc(0.45em + 1.25rem + 5px);
	padding: 0 0.5rem;
	display: inline-block;
	width: 250px;
	margin: 0 0 0 10px;
}

.case-list-table .card .table thead th input,
.case-list-table .card .table thead th select {
	height: calc(0.45em + 1.25rem + 5px);
	padding: 0 10px;
	display: none;
}

.case-list-table .card .table thead th label {
	// margin: 0 0 0 20px;
	display: none;
}

.card-translucent {
	background-color: rgba(18, 91, 152, 0.08);
}

.card-img-top {
	@if ($enable-shadows == false) {
		width: calc(100% + 2px);
		margin-left: -1px;
		margin-top: -1px;
	}
}

.card-img-bottom {
	@if ($enable-shadows == false) {
		width: calc(100% + 2px);
		margin-left: -1px;
		margin-bottom: -1px;
	}
}

// Card decks

.card-deck {
	.card {
		margin-bottom: $grid-gutter-width;
	}
}

// Card with shdadow

.card {
	&.shadow {
		border: 0 !important;
	}
}

//
// Columns
//

.card-columns {
	@include media-breakpoint-up(sm) {
		column-count: 1;
	}

	@include media-breakpoint-up(md) {
		column-count: 2;
	}

	@include media-breakpoint-up(xl) {
		column-count: $card-columns-count;
		column-gap: $card-columns-gap;
	}
}
